<template>
  <div class="cpt-task-achievement-search">
    <div style="display: flex; align-items: center;">
      <el-form
        :model="search_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
      <el-form-item label="所属单位:" class="aaxx">
          <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="search_form.orgId"
          :options="searchname"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>

        </el-form-item>
        <el-form-item label="任务名称:">
          <el-input
            v-model="search_form.name"
            placeholder="请输入任务名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item >
          <div @click="nameSerach" class="linear-btn">
            搜索
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from '@/api'
export default {
  data() {
    return {
      searchId: null,
      search_form: {
        organizationId :null,
        name:null
      },
      searchname:[],
    };
  },
  methods:{
    getTreeData(data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i].child.length < 1) {
					// children若为空数组，则将children设为undefined
					data[i].child = undefined;
				} else {
					// children若不为空数组，则继续 递归调用 本方法
					this.getTreeData(data[i].child);
				}
          // this.searchname.push({[data[i].name]:data[i].id})
			}
      return data;
		},
   async nameSerach(){
     this.searchId=='' ? this.search_form.organizationId=null : this.searchId ;
      if (!this.searchname.length) {
        let { data } = await API.AIRWAY.getAllOrganizationBack()
        // console.log(data);
          this.searchname = this.getTreeData(data)
      }
      if (this.searchId) {
        let index= this.searchname.find((item)=>{
           return Object.keys(item)==this.searchId
         })
         this.search_form.organizationId=Object.values(index)[0]
      }
      this.$emit('on-search', this.search_form)
    }
  },
  mounted(){
    this.nameSerach()
  }
};
</script>

<style lang="scss" scoped>
 .cpt-task-achievement-search {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  ::v-deep .linear-form.el-form .el-form-item .el-form-item__content .el-input.el-input--suffix .el-input__inner{
      width: 200px;
      font-size: 16px;
    }
  
</style>