<template>
  <Box>
    <div class="page-service-task-report">
      <Search @on-search="handle_search" />
      <div class="achi-list-wrap">
        <div class="achi-list">
          <div class="achi-item" v-for="item in dataSource" :key="item.id" >
              <div class="achi-item" slot="reference">
                <div class="achi-icon-box">
                  <img
                    class="achi-icon"
                    :src="require(`@/assets/platform/achi-01.png`)"
                    alt="achi-type"
                    @click="$router.push(`/platform/order/report/${item.id}`)"
                  />
                </div>
                <div :title="item.name" class="achi-text-box">
                  <el-popover
                    placement="right-start"
                    width="200"
                    trigger="hover"
                    :content="item.taskTitle">
                    <input
                    slot="reference"
                    :ref="`achi-${item.id}`"
                    type="text"
                    v-model="item.taskTitle"
                    readonly
                  />
                  </el-popover>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="table-pagination">
        <Pagination
          @size-change="handle_size_change"
          @page-change="handle_page_change"
          :current-page="pagination.page || 1"
          :page-sizes="pagination.pageSizes || [10, 20, 50, 100]"
          :page-size="pagination.pageSize || 10"
          :total="pagination.total || 0"
          :size="size"
        />
      </div>
    </div>
  </Box>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

import Box from "@/components/platform/common/box";
import Pagination from "@/components/common/pagination";
import Search from "./search";

import API from "@/api";
export default {
  components: { Box, Pagination, Search },
  data() {
    return {
      search: {},
      dataSource: [],
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
      },
      size:null
    };
  },
  methods: {
    async async_get_list() {
       const { page, pageSize } = this.pagination
       let res = await API.REPORT.reportList({
         ...this.search,
        page,
        pageSize,
      });
      if (res.status===1) {
      this.dataSource = res.data.records || [];
      this.pagination.total = res.data.total;
      }else{
        this.$message.error(res.message)
        }
    },
    handle_search(data) {
      this.pagination.page = 1;
      this.search = data;
      this.async_get_list();
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.async_get_list();
    },
    handle_page_change(page) {
      this.pagination.page = page;
      this.async_get_list();
    },
  },
  async created() {
  await this.async_get_list()
  }
};
</script>

<style lang="scss" scoped>
.page-service-task-report {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .achi-list-wrap {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .achi-list {
      display: flex;
      flex-wrap: wrap;
      margin: 39px -73px -32px 0px;
      .achi-item {
        width: 156px;
        height: 150px;
        margin: 0 73px 32px 0;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        .achi-icon-box {
          width: 156px;
          height: 115px;
          position: relative;
          .achi-icon {
            width: 100%;
            height: 100%;
          }
        }
        .achi-text-box {
          input {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #a8bdd4;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            padding: 0;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .table-pagination {
    flex-shrink: 0;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>